<template>

  <!-- Copy Desk -->
  <ArticleTable
    heading="Copy Desk"
    :articles="copy_desk"
    :hide="['stage']"
    date-label="Created"
    date-field="created_at"
    class="copy-desk"
  />

</template>

<script>

// Components
import ArticleTable from '@/components/widgets/articles/ArticleTable'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('articles')

export default {
  name: 'CopyDesk',
  // -------------
  // Components ==
  // -------------
  components: { 
    ArticleTable
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'copy_desk'
    ])
  }
}
</script>

<template>

  <!-- Drafts -->
  <ArticleTable
    heading="Drafts"
    :articles="drafts"
    :hide="['stage']"
    date-label="Created"
    date-field="created_at"
    class="drafts"
  />

</template>

<script>

// Components
import ArticleTable from '@/components/widgets/articles/ArticleTable'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('articles')

export default {
  name: 'Drafts',
  // -------------
  // Components ==
  // -------------
  components: { 
    ArticleTable
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'drafts'
    ])
  }
}
</script>

<template>

  <!-- Publishing Schedule -->
  <ArticleTable
    heading="Publishing Schedule"
    :articles="scheduled"
    :hide="['stage']"
    date-label="Publish"
    date-field="published_at"
    class="publishing-schedule"
  >

    <!-- Actions -->
    <template #actions>

      <!-- Edit Schedule Button -->
      <j-button
        v-if="$user.isAdmin && scheduled.length"
        label="Schedule"
        type="header"
        icon="edit"
        class="float-right"
        :to="{ name: 'articles-schedule' }"
      />

    </template>
  </ArticleTable>
</template>

<script>

// Components
import ArticleTable from '@/components/widgets/articles/ArticleTable'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('articles')

export default {
  name: 'PublishingSchedule',
  // -------------
  // Components ==
  // -------------
  components: { 
    ArticleTable
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'scheduled'
    ])
  }
}
</script>

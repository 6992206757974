<template>

  <!-- Articles Index View -->
  <j-page
    title="Articles"
    :loading="loading"
    fill
    id="articles-index-view"
  >

    <!-- Actions -->
    <template #actions>

      <!-- Search Bar -->
      <j-search @expand="handleSearch" />

      <!-- Add Article Button -->
      <j-button
        type="icon"
        help="Add an article to the site"
        icon="plus"
        :to="{ name: 'draft-create' }"
      />

    </template>

    <!-- Left - Incoming [Copy / Drafts] -->
    <template #left>

      <!-- Copy Desk -->
      <CopyDesk />
  
      <!-- Drafts -->
      <Drafts />

    </template>

    <!-- Right - Outgoing [Scheduled / Recent] -->
    <template #right>

      <!-- Scheduled -->
      <PublishingSchedule />
  
      <!-- Recent -->
      <RecentlyPublished />

    </template>  

    <!-- Dialogs - Delete Article -->
    <template #dialog>

      <!-- Comment modal for deleting articles -->
      <DeleteArticleDialog />

    </template>  
  </j-page>
</template>

<script>

// Components
import DeleteArticleDialog from '@/components/Articles/DeleteArticleDialog'
import CopyDesk from '@/components/Articles/CopyDesk'
import Drafts from '@/components/Articles/Drafts'
import PublishingSchedule from '@/components/Articles/PublishingSchedule'
import RecentlyPublished from '@/components/Articles/RecentlyPublished'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapActions } = createNamespacedHelpers('articles')

export default {
  name: 'ArticlesIndexView',
  // -------------
  // Components ==
  // -------------
  components: {
    DeleteArticleDialog,
    CopyDesk,
    Drafts, 
    PublishingSchedule,
    RecentlyPublished
  },
  // -------------
  // Middleware ==
  // -------------
  beforeRouteEnter(_to, _from, next) {
    next(vm => {
      vm.fetchArticles()
    })
  },
  // -----------
  // Metadata ==
  // -----------
  metaInfo() {
    return {
      title: 'Articles'
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    // Vuex
    ...mapGetters([
      'loading'
    ])
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    // Actions
    ...mapActions([
      'fetchArticles'
    ]),

    // -----------
    // Handlers ==
    // -----------

    // Search
    handleSearch () {
      this.$router.push({ name: 'articles-search' })
    }
  }
}
</script>
